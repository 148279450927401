<template>
  <div class="main">
    <van-nav-bar :title="showqrText" left-arrow @click-left="onClickLeft" />
    <div class="content">
      <div style="height:auto;position: relative;display: block;padding:0 10px 0 10px;box-sizing: border-box;margin-top: 10px;">
        <div class="info" style="height:auto;position:relative;display:block;border-radius:6px;width:100%;box-sizing: border-box;background: #f2f2f2;padding: 10px 10px;box-sizing: border-box;">
          <van-cell center :border="false">
            <template #icon>
              <van-image
                :src="ghsInfo.logo"
                lazy-load
                style="width: 40px; height: 40px; display: block;border-radius: 4px;"
              >
                <template v-slot:loading>
                  <van-loading type="spinner" size="20" />
                </template>
              </van-image>
            </template>
            <template #title  @click="goghsZiZhi(ghsInfo.id)">
              {{ghsInfo.merchname}}
            </template>
            <template #label>
              销量{{ghsInfo.num_salse}}  共{{ghsInfo.num_goods}}款商品
            </template>
          </van-cell>
        </div>
      </div>
      

      <div class="title">卖家信息</div>
      <div style="height:auto;position: relative;display: block;padding:0 10px 0 10px;box-sizing: border-box;margin-top: 10px;">
        <div class="info" style="height:auto;position:relative;display:block;border-radius:6px;width:100%;box-sizing: border-box;background: #f2f2f2;padding: 10px 0;">
          <van-cell title="认证信息" :value="ghsInfo.renzheng_info" />
          <van-cell title="主体名称" :value="ghsInfo.zhitu" />
          <van-cell title="社会统一信用代码" :value="ghsInfo.zhuti_code" />
        </div>
      </div>
      <div class="title">经营资质</div>
      <div style="height:auto;position: relative;display: block;padding:0 10px 0 10px;box-sizing: border-box;margin-top: 10px;">
        <div class="info" style="height:auto;position:relative;display:block;border-radius:6px;width:100%;box-sizing: border-box;background: #f2f2f2;padding: 10px 10px;box-sizing: border-box;">
          <van-image
            v-for="(item,index) in ghsInfo.zizhi_img"
            :key="index"
            :src="item"
            lazy-load
            style="width: 100%;"
            @click="yulan(ghsInfo.zizhi_img, index)"
          >
            <template v-slot:loading>
              <van-loading type="spinner" size="20" />
            </template>
          </van-image>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { ImagePreview } from 'vant';
export default {
  name: "Ghsdetail",
  data() {
    return {
      screenWidth: document.body.clientWidth, // 屏幕宽
      screeHeight: document.body.clientHeight, // 屏幕高
      userinfo: {},
      showqrText: "商家资质", // 二维码的名称
      isLogin: localStorage.getItem("DK_ISLOGIN")
        ? localStorage.getItem("DK_ISLOGIN")
        : false,
      shareid: 0,
      merchid: 0,
      ghsid: 0,
      ghsInfo: {}
    };
  },
  mounted() {
    if (this.$route.query.shareid) {
      this.shareid = this.$route.query.shareid
    }else if (localStorage.getItem("DK_MEMBERID")) {
      this.shareid = localStorage.getItem("DK_MEMBERID");
    }
    this.merchid = this.$route.query.merchid ? this.$route.query.merchid : 0;
    this.ghsid = this.$route.query.ghsid ? parseInt(this.$route.query.ghsid) : 0;
    this.getGHSInfo();
  },
  methods: {
    onClickLeft() {
      this.$router.go(-1);
    },
    // 获取供货商信息
    getGHSInfo(){
      let _that = null;
      _that = this;
      _that.$axios.defaults.headers.common['Authorization'] = localStorage.getItem("DK_UID");
      _that.$axios
        .post(
          _that.$store.state.domain + 'web/goods/ghs_info',
          _that.$qs.stringify({
            ghsid: _that.ghsid
          })
        )
        .then(function(response){
          _that.ghsInfo = response.data.ghs_info
        })
    },
    // 预览
    yulan(imgArr, index){
      ImagePreview({
        images: imgArr,
        startPosition: index,
        closeable: true,
      });
    },
  }
};
</script>
<style lang="less">
.main {
  height: 100%;
  width: 100%;
  background: #fff;
  .van-nav-bar__left .van-icon {
    color: #333;
  }
  .content {
    padding-bottom: 60px;
    overflow: hidden;
    .van-cell {
      padding: 10px 12px;
      text-align: left;
      .van-cell__title {
        flex: 1;
        font-size: 15px;
        font-weight: bold;
        color: #333;
        line-height: 20px;
        padding-left: 10px;
      }
      .van-cell__label {
        font-size: 13px;
        font-weight: normal;
      }
    }
    .title {
      font-size: 16px;
      color: #383838;
      width: 100%;
      padding-left: 20px;
      box-sizing: border-box;
      margin-top: 24px;
      text-align: left;
    }
    .info {
      .van-cell {
        background-color: #f2f2f2;
        .van-cell__value {
          text-align: left;
        }
      }
    }
  }

}
</style>
